'use client';

export default function LoginCredentials({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className='lg:p-8'>
      <div className='mx-auto flex w-full flex-col justify-center gap-6 sm:w-[350px]'>
        {children}
      </div>
    </div>
  );
}
